import React, { Component } from 'react'
import './MyPlayer.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import playIcon from './play.png'
import Voting from './Voting.js'

import ReactPlayer from 'react-player'
import { Slider, Direction, PlayerIcon, FormattedTime } from 'react-player-controls'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExpand, faCompress, faPlay, faPause, faPlayCircle, faVolumeDown, faVolumeMute } from '@fortawesome/free-solid-svg-icons'

import axios from './myAxios';

import UserAction from './UserAction.js'

var watchedPercentage = 0;
var watchedSeconds = 0;
var timer = 0;
var votedSeconds = {}; //Keeping which Votings have been completed by second

class MyPlayer extends Component {

  state = {
    url: "",
    pip: false,
    playing: false,
    controls: false,
    light: false,
    volume: 1.0,
    muted: false,
    played: 0,
    loaded: 0,
    duration: 0,
    playbackRate: 1.0,
    loop: false,
    direction: "HORIZONTAL",
    fullscreen: false,
    voting: "",
    idle: false,
    currentVoting: {},
    currentVotingId: 0,
    videoState: null,
    playedSeconds: 0,
    seeking: false
  }

  handlePlayPause = () => {
    /*let hasVote = false;
    if(this.state.voting != ""){ //Voting questions have loaded
      for(const property in this.state.voting){
        //console.log(this.state.voting[property]["seconds"]);
        if(this.state.voting[property]["seconds"] == parseInt(this.state.playedSeconds)){
          if(votedSeconds[parseInt(this.state.playedSeconds)]){ //Change it to work by id instead of seconds.

          }
          else{
            this.setState({ currentVoting: this.state.voting[property] });
            this.setState({ playing: false });
            hasVote = true;
          }
          break;
        }
      }
    }
    if(!hasVote){
      this.setState({ playing: !this.state.playing })
      this.setState({ currentVoting: "" });
    }*/
    this.setState({ playing: !this.state.playing })
    if(!this.state.playing){
      UserAction.Save("Play "+this.props.id, "Clicked");
    } else {
      UserAction.Save("Pause "+this.props.id, "Clicked");
    }
    
  }

  handleStop = () => {
    this.setState({ url: null, playing: false })
  }

  handleToggleLight = () => {
    this.setState({ light: !this.state.light })
  }

  handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop })
  }

  handleVolumeChange = e => {
    this.setState({ volume: parseFloat(e.target.value) })
  }

  handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted })
  }

  handleSetPlaybackRate = e => {
    this.setState({ playbackRate: parseFloat(e.target.value) })
  }

  handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip })
  }

  handlePlay = () => {
    //console.log('onPlay')
    this.setState({ playing: true })
  }

  handleEnablePIP = () => {
    //console.log('onEnablePIP')
    this.setState({ pip: true })
  }

  handleDisablePIP = () => {
    //console.log('onDisablePIP')
    this.setState({ pip: false })
  }

  handlePause = () => {
    //console.log('onPause')
    this.setState({ playing: false })
  }

  handleSeekMouseDown = e => {
    this.setState({ seeking: true })
  }

  handleSeekChange = e => {
    console.log(parseFloat(e.target.value))
    console.log(watchedPercentage)
    if(parseFloat(e.target.value) > watchedPercentage){
      //not allowed
      this.setState({ played: watchedPercentage }) //Can only go up to the watchedPercentage, not further
    }
    else{
      this.setState({ played: parseFloat(e.target.value) })
    }
  }

  handleSeekMouseUp = e => {
    this.setState({ seeking: false })
    this.player.seekTo(parseFloat(e.target.value))
  }

  handleProgress = state => {
    //console.log('onProgress', state)

    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      if(state.played > watchedPercentage){
        watchedPercentage = state.played;
        if(parseInt(state.playedSeconds) >= watchedSeconds+10){
          console.log("Watched 10 seconds");
          watchedSeconds = parseInt(state.playedSeconds);
          this.save();
        }
        this.props.setPlayedSeconds(parseInt(state.playedSeconds));
      }
      if(this.state.voting != ""){ //Voting questions have loaded
        for(const property in this.state.voting){
          //console.log(this.state.voting[property]["seconds"]);
          if(this.state.voting[property]["seconds"] == parseInt(state.playedSeconds)){
            console.log(votedSeconds[parseInt(state.playedSeconds)]);
            if(votedSeconds[parseInt(state.playedSeconds)]){

            }
            else{
              this.setState({ currentVoting: this.state.voting[property] });
              this.setState({ currentVotingId: property });
              this.setState({ playing: false });
            }
            break;
          }
        }
      }
      this.setState({ playedSeconds: state.playedSeconds });
      this.setState({ played: state.played });
      this.setState({ videoState: state });
    }
  }

  doneHandler = () =>{
    console.log("DONE");
    console.log(parseInt(this.state.playedSeconds));
    votedSeconds[parseInt(this.state.playedSeconds)] = true; //Keeping track of which Votes the user has completed
    this.setState({ currentVoting: {} });
    this.setState({ currentVotingId: 0 });
    this.setState({ playing: true });
  }

  handleEnded = () => {
    //console.log('onEnded')
    this.setState({ playing: this.state.loop })
  }

  handleDuration = (duration) => {
    //console.log('onDuration', duration)
    this.setState({ duration })
  }

  handleClickFullscreen = () => {
    if(screenfull.isFullscreen){
      screenfull.exit();
    }
    else{
      screenfull.request(findDOMNode(this.player).parentNode.parentNode)
    }
  }

  handleClick = () => {
  //  screenfull.request(findDOMNode(this.player))
  }

  ref = player => {
    this.player = player
  }

  handleCursor = () => {
    timer = 0;
    if(this.state.idle){
      this.setState({ idle: false });
    }
  }

  //save watched percentage for each user
  save(){
    let values = {};
    values["watched_percentage"] = watchedPercentage;
    values["content_id"] = this.props.id;
    axios.post(`/admin/api/saveWatchedPercentage.php`, values)
      .then(res => {
        console.log(res);
      });
  }

  componentDidMount () {
    timer = 0;
    if(this.state.idle){
      this.setState({ idle: false });
    }

    axios.get(`/admin/api/getContentDetails.php`, { params: { id: this.props.id } })
      .then(res => {
        console.log(res);
        this.setState({voting: res.data["voting"]});
        this.setState({url: res.data["content_material_url"]});
        console.log(this.state.voting);
      });

    setInterval(() => {
      timer += 1;
      if(timer >= 3){
        if(!this.state.idle){
          this.setState({ idle: true });
        }
      }
    }, 1000);

    //get watched percentage of user
    watchedPercentage = 0;
    let values = {};
    values["content_id"] = this.props.id;
    axios.post(`/admin/api/getWatchedPercentage.php`, values)
    .then(res => {
      console.log(res.data["watchedPercentage"]);
      watchedPercentage = res.data["watchedPercentage"];
      setTimeout(() => {
        this.player.seekTo(parseFloat(res.data["watchedPercentage"]));
      },100);
    });
  }

  render () {
    const { url, playing, controls, light, volume, muted, loop, played, loaded, duration, playbackRate, pip } = this.state
    const SEPARATOR = ' · '

    return (
      <div className={"background-secondary"} style={{width: "100%", height: "100%"}}>
        <div className={"myPlayerContainer"} onMouseMove={ this.handleCursor } style={{position: "relative", width: "100%", height: "100%"}}>
          <div className={"centerPlay"} style={{pointerEvents: "all", transition: "all 0.2s", position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -50%)", opacity: this.state.playing?"0":"1"}}>
            <div style={{color: "white", position: "absolute", left: "50%", top: "50%", transform: "translate(-40%, -50%)", fontSize: "54%"}}>
              <FontAwesomeIcon icon={faPlay}/>
            </div>
            <div className={"secondary-color"}>
              <FontAwesomeIcon icon={faPlayCircle}/>
            </div>
          </div>
          <div style={{position: "absolute", width: "100%", height: "100%", top: "0px", left: this.state.currentVotingId==0?"-100%":"0", opacity: this.state.currentVotingId==0?"0":"1", transition: "all 0.5s" /*display: this.state.currentVotingId==0?"none":"block"*/}}>
            <Voting
              data={this.state.currentVoting}
              votingId={this.state.currentVotingId}
              doneHandler={this.doneHandler}
            />
          </div>
          <div onClick={this.handlePlayPause} onDoubleClick={this.handleClickFullscreen} className={screenfull.isFullscreen?"fullscreen":""} style={{padding: "25px 0px", width: "100%", height: "100%", cursor: this.state.idle?"none":"default"}}>
            <ReactPlayer
              ref={this.ref}
              className='react-player'
              width='100%'
              height='100%'
              url={url}
              pip={pip}
              playing={playing}
              controls={controls}
              light={light}
              loop={loop}
              playbackRate={playbackRate}
              volume={volume}
              muted={muted}
              //onReady={() => /*console.log('onReady')*/}
              //onStart={() => /*console.log('onStart')*/}
              onPlay={this.handlePlay}
              onEnablePIP={this.handleEnablePIP}
              onDisablePIP={this.handleDisablePIP}
              onPause={this.handlePause}
              //onBuffer={() => /*console.log('onBuffer')*/}
              //onSeek={e => /*console.log('onSeek', e)*/}
              onEnded={this.handleEnded}
              //onError={e => /*console.log('onError', e)*/}
              onProgress={this.handleProgress}
              onDuration={this.handleDuration}
              progressInterval={1000} //Setting to 1000ms just to be sure that it will pass through all seconds and not skip any
            />
        </div>
          <div className={"controlsContainer"} style={{pointerEvents: "none", transition: "all 0.3s", opacity: this.state.idle?0:1}}>
            <FontAwesomeIcon icon={this.state.playing?faPause:faPlay} onClick={this.handlePlayPause} style={{pointerEvents: "all", color: "white", marginRight: "16px", cursor: "pointer"}}/>
            <div style={{pointerEvents: "all", display: "inline-block", cursor: "pointer"}} onClick={this.handleToggleMuted} className={"volume"}>
              <FontAwesomeIcon icon={this.state.muted?faVolumeMute:faVolumeDown} style={{color: "white"}}/>
              <div className={"volumeControl"}>
                <input type='range' min={0} max={1} step='any' value={volume} disabled={this.state.muted} onChange={this.handleVolumeChange}/>
              </div>
            </div>
            <div style={{fontSize: "14px", display: "inline-block", position: "relative", top: "-3px", fontFamily: "monospace", userSelect: "none"}}>
              {new Date(parseInt((this.state.playedSeconds||0) * 1000)).toISOString().substr(11, 8)} / {new Date(parseInt((this.state.duration||0) * 1000)).toISOString().substr(11, 8) }
            </div>

            <div onClick={this.handleClickFullscreen} style={{cursor: "pointer", pointerEvents: "all", float: "right", fontSize: "24px", position: "absolute", top: "25px", right: "10px", padding: "15px"}}>
              <FontAwesomeIcon icon={screenfull.isFullscreen?faCompress:faExpand} style={{color: "white"}}/>
            </div>
            <input
              style={{pointerEvents: "all", width: "100%"}}
              type='range' min={0} max={0.999999} step='any'
              value={this.state.played}
              onMouseDown={this.handleSeekMouseDown}
              onChange={this.handleSeekChange}
              onMouseUp={this.handleSeekMouseUp}
              onTouchEnd={this.handleSeekMouseUp}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default MyPlayer
