import React, { Component } from 'react'
import './CookiesPolicy.scss';

import logo from "./images/exceed.svg";

import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'
import HamburgerMenu from 'react-hamburger-menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import Popup from './Popup.js';
import LoginModal from './Modals/LoginModal.js';
import HomeHeader from './HomeHeader.js';
import UserAction from './UserAction';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class CookiesPolicy extends Component {
  state = {
    modalLoginVisible: false,
    hamburgerOpen: false
  }

  componentDidMount() {
    UserAction.Save("Cookies", "Visited");
    if(this.props.match.params.login == "login"){
      this.setState({modalLoginVisible: true})
    }
  }

  handleHamburgerClick() {
      this.setState({
          hamburgerOpen: !this.state.hamburgerOpen
      });
  }

  render() {
    return (
      <div className="body background">

        <HomeHeader login={this.props.match.params.login}/>


        <div className="my-container secondary-font" style={{marginTop: "20px"}}>
          <div style={{marginBottom: '30px'}}>
            <div className="title-margin title-text" style={{}}>
              <span style={{fontWeight: 'bold'}}>
                Cookies Policy
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12" style={{fontSize: '18px'}}>
              <p>The purpose of this Cookie Notice is to explain our practices regarding the collection of information through the use of cookies and other tracking technologies (like pixels, web beacons, etc.) on our websites. Below explains the functionality of these technologies, and a detailed list of cookies we use, with a description of its purpose(s), and more information about how to set your preferences. </p>
              <p>Please note that this Cookie Notice does not apply to, and we are not responsible for, the cookie practices of third-party websites which may be linked to our websites. Once you leave our websites, we do not have control over the placement of cookies on other websites, even if you are directed to them from our websites.</p>
              <p>&nbsp;</p>
              <p><strong>What are cookies?</strong></p>
              <p>Cookies are small text files that your web browser stores on your device when you access certain websites. Cookies allow the website to recognize the user and store certain information about the user’s preferences or past actions. Most web pages contain elements from multiple web domains so when you visit the website, your browser may receive cookies from several sources. Cookies allow you to navigate between pages efficiently, remember preferences, and generally improve the user experience. They can also be used to tailor content to your interests through tracking your browsing across websites.</p>
              <p>Occasionally, we and our service providers may use other technologies, such as scripts, web beacons, and iFrames on our websites and may deploy these tags through a third-party advertising partner or a web analytical service partner. These tags are placed on both online advertisements that bring users to our websites, as well as to different pages of our websites. We use these technologies for various purposes, including to measure the visitors’ responses to our websites and the effectiveness of our advertising campaigns (including how many times a page is opened and which information is consulted) as well as to evaluate your use of our websites. Within this Cookie Notice, the term “cookies” is also used to describe other technologies that have similar purposes and functions as cookies. </p>
              <p>You can choose to refuse certain cookies, however, by doing so the functionality of our websites may be limited. You can manage your cookie preferences in your browser settings, and also by visiting our Cookie Preference Center, which appears as a banner on our websites.</p>
              <p>Third party cookies are cookies set by someone other than the website owner for purposes such as collecting information on user behavior, demographics, or personalized marketing. When using our websites you may encounter embedded content, such as YouTube videos, or you may be directed to other websites. These websites and embedded content may use their own cookies.</p>
              <p>&nbsp;</p>
              <p><strong>What types of cookies does Vertex use?</strong></p>
              <p><u>Essential Cookies</u></p>
              <p>Essential cookies are necessary in order to enable you to move around the website and use its features. Without these cookies, certain actions (such as accessing gated content or remembering your cookie preferences) cannot be provided.</p>
              <p>Essential cookies do not gather information about you that could be used for marketing purposes and are not used to track your browsing. These cookies are necessary for the website to function and cannot be disabled. </p>
              <p>&nbsp;</p>
              <p><u>Performance & Analytics Cookies</u></p>
              <p>We make use of performance and analytics cookies and other technologies to analyze how our visitors use our websites and to monitor website performance, including those by Google Analytics. This allows us to provide a high quality experience by customizing our websites and to quickly identify and fix any issues that arise. For example, we might use performance cookies to keep track of which pages are most popular, which method of linking between pages is most effective, and to determine why some pages are receiving error messages. These cookies also allow us to count visits and traffic sources so we can measure and improve the performance of our websites. </p>
              <p>Our websites also use Google Analytics, a web analytics service provided by Google, Inc. (“Google”). Google Analytics uses cookies to collect information that is transmitted and stored by Google on services in the United States in accordance with its privacy practices. We use Google Analytics to optimize traffic to and between our websites and integrate and optimizing web pages where appropriate. You can learn about Google’s practices by going to <a href="https://www.google.com/policies/privacy/partners/" target="_blank">www.google.com/policies/privacy/partners/</a>, and opt-out of them by downloading the Google Analytics opt-out browser add-on, available at <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.</p>
              <p>&nbsp;</p>
              <p><u>Functionality & Preference Cookies</u></p>
              <p>We make use of cookies to provide you with certain functionality on our websites. These cookies are not essential but enhance your use of our websites. For example, we may use cookies to remember choices you make (such as your preferred language or region you are in), to recognize the platform from which you access the site, to enable embedded videos, and to provide enhanced and more personal features. If these cookies are disabled in the Cookie Preference Center, some or all of these services may not function properly.</p>
              <p>&nbsp;</p>
              <p><u>Targeting & Advertising Cookies</u></p>
              <p>We use cookies to enhance our marketing and advertising campaigns, as well as to deliver content that we believe will be most relevant to you based on your use of our websites. Targeting and advertising cookies track user behavior and store user attributes, such as demographic and interest data, to provide more personalized content (in some cases, including ad content) to visitors both on our websites and once you leave our website, to segment visitors into remarketing campaigns, and help us improve and analyze our digital marketing efforts. These cookies may be set through our site by our advertising partners. These cookies are not essential for the website to function, but you may notice less relevant ads and content (including marketing content) if they are disabled.</p>
              <p>&nbsp;</p>
              <p><u>Social Media Cookies</u></p>
              <p>We may make use of cookies to enable you to more easily share our content on social media with your friends and networks. These cookies may also track your browser across other sites and build up a profile of your interests. In some situations, we may use social media cookies provided by the social media services themselves (or the social media services may set the cookies), which may allow the social media services to track your browser across other sites and build up a profile of your interests. This may impact the content and messages you see on other websites you visit. If you choose not to allow these cookies, you will not be able to use these sharing tools on our site. </p>
              <p>&nbsp;</p>
              <p><strong>Data Retention</strong></p>
              <p>We will retain your cookies and consent only for as long as necessary to fulfill the purpose(s) for which the cookies and consent were collected, depending on the purpose(s) for which the information was collected, the nature of the information, any contractual relationship that may govern the retention of the data, and our legal or regulatory obligations. We will then destroy your information or anonymize the information in accordance with applicable law.</p>
              {/*<p><strong>Important Information</strong></p>
      				<p>
      					In the website you are accessing (https://cfexceed2020.com), we only use the Strictly Necessary cookies. You can withdraw your consent at any time you want, but in such case, you will not be able to access the content of the website. To withdraw your consent from cookies click <span onclick="Withdraw();" style="cursor: pointer; text-decoration: underline; color: #007bff;">here.</span>
      				</p>
      				<p>&nbsp;</p>*/}
      				<p>&nbsp;</p>
              <p><strong>Cookie Preferences</strong></p>
              <p>You may change your preferences by using our Cookie Preference Center. The tool will record when you have consented to our use of Cookies. The Cookie Preference Center allows you to control functionality cookies, analytics and performance cookies, and advertising cookies set when using our websites. Essential cookies may not be disabled through the tool, nor can it be used to block cookies on third-party websites linked from our websites. </p>
              <p>You may also disable all cookies (including essential cookies) through your browser settings by following the instructions for your specific browser as explained at <a href="http://www.allaboutcookies.org/manage-cookies/" target="_blank">http://www.allaboutcookies.org/manage-cookies/</a>. Please note that some of our services may not function properly if cookies are disabled. </p>
              <p>Furthermore, some of our service providers that set cookies via our site on our behalf may offer the ability to opt-out of cookies via the Network Advertising Initiative’s consumer opt-out <a href="http://optout.networkadvertising.org/#!/" target="_blank">tool</a>, the European Interactive Digital Advertising Alliance’s consumer opt-out <a href="http://www.youronlinechoices.eu/" target="_blank">tool</a>, or the <a href="http://optout.aboutads.info/#!/" target="_blank">Digital Advertising Alliance’s Consumer Choice Page</a>.</p>
              <p>&nbsp;</p>
              <p><strong>Changes to the Cookie Notice</strong></p>
              <p>We may update this Cookie Notice and we would encourage you to review the notice from time to time to stay informed of how we are using cookies. </p>
              <p>&nbsp;</p>
              <p><strong>How to Contact Us</strong></p>
              <p>You may contact us with questions, comments, or complaints about this Cookie Notice or our privacy practices, or to request access to or correction of your information. When raising a request or complaint, please provide sufficient details (including your relationship with us) and any relevant documentation. The contact information for our global Privacy Office and global data protection officer for all Vertex affiliates except Vertex Pharmaceuticals (Germany) GmbH is:</p>
              <p>Vertex Pharmaceuticals Incorporated</p>
              <p>Attn: Legal Department – Privacy Office</p>
              <p>50 Northern Avenue</p>
              <p>Boston, MA 02210</p>
              <p>E-mail: <a href='mailto:privacy@vrtx.com' target='_top'>privacy@vrtx.com</a></p>
              <p>&nbsp;</p>
              <p>Contact information for the data protection officer for Vertex Pharmaceuticals (Germany) GmbH is:</p>
              <p>Holzhofer Consulting GmbH</p>
              <p>Lochhamer Str. 31</p>
              <p>D-82152 Planegg (Germany)</p>
              <p>E-mail: <a href='mailto:dsb-vertex@holzhofer-consulting.de' target='_top'>dsb-vertex@holzhofer-consulting.de</a></p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CookiesPolicy
