import React, { Component } from 'react'
import './Registration.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'

import axios from './myAxios';

import TextInput from './RegistrationInput/TextInput.js';
import OptionInput from './RegistrationInput/OptionInput.js';
import CountryInput from './RegistrationInput/CountryInput.js';
import TelephoneInput from './RegistrationInput/TelephoneInput.js';
import EmailInput from './RegistrationInput/EmailInput.js';
import Popup from './Popup.js';
import LoginModal from './Modals/LoginModal.js';
import HomeHeader from './HomeHeader.js';

import UserAction from './UserAction';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class Registration extends Component {
  state = {
    fields: {},
    countries: {},
    modalVisible: false,
    modalTitle: "",
    modalText: "",
    hasError: {},
    showRed: false,
    modalLoginVisible: false
  }

  componentDidMount() {
    UserAction.Save("Registration", "Visited");
    axios.get(`/admin/api/getRegistrationFields.php`)
      .then(res => {
        //let hasError = {};
        //for(const property in res.data["personal_information"]){
        //  hasError[property] = false;
        //}
        this.setState({
        //  hasError: hasError,
          fields: res.data["personal_information"],
          countries: res.data["countries"]
        });
        console.log(res.data);
      });
  }

  handleInputChange = (property, value) => {
    this.state.fields[property]["value"] = value;
    console.log(this.state.fields[property]);
  }

  handleOnErrorChange = (property, value) => {
    let hasError = this.state.hasError;
    hasError[property] = value;
    this.setState({hasError: hasError});
  }

  renderFields() {
    let result = [];
    let fields = this.state.fields;

    for(const property in fields){
      if(fields[property]["type"] == "text"){
        result.push(<TextInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} type="text"/>)
      }
      else if(fields[property]["type"] == "option"){
        result.push(<OptionInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} type="text"/>)
      }
      else if(fields[property]["type"] == "telephone"){
        result.push(<TelephoneInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} type="text"/>)
      }
      else if(fields[property]["type"] == "email"){
        result.push(<EmailInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} type="text"/>)
      }
      else if(fields[property]["type"] == "country"){
        result.push(<CountryInput onChange={this.handleInputChange} onErrorChange={this.handleOnErrorChange} showRed={this.state.showRed} name={property} options={fields[property]} countries={this.state.countries} type="text"/>)
      }
    }
    return result;
  }

  register(){
    let values = {};
    //let fields = this.state.fields;
    let valid = true;
    //let hasError = {};

    /*for(const property in fields){
      hasError[property] = false;
      if(fields[property]["required"] == 1){
        if(fields[property]["value"] && fields[property]["value"].trim() != ""){
          values[property] = fields[property]["value"];
        }
        else{
          hasError[property] = true;
          valid = false;
        }
      }
    }
    this.setState({hasError: hasError});
    */
    let fields = this.state.hasError;
    for(const property in fields){
      if(fields[property]){
        valid = false;
      }
    }
    if(!valid){
      this.setState({
        showRed: true, //Initially disabled because we don't want to show the error messages on load, only after the first register click
        modalTitle: "Warning",
        modalText: "Please fill in all fields in red.",
        modalVisible: true
      });
      return;
    }

    fields = this.state.fields; //values

    for(const property in this.state.fields){
      values[property] = fields[property]["value"];
    }

    axios.post(`/admin/api/register.php`, values)
      .then(res => {
        if(res.data == "1"){
          UserAction.Save("Registered", "Clicked");
          this.setState({
            modalTitle: "Registration Submitted",
            modalText: `<span class="primary-color" style="font-weight: bold">Thank you for your registration.</span><br/>Please check your email Inbox (including Spam/Junk), and click on the link to verify your registration.`,
            modalVisible: true
          });
        }
        else{
          this.setState({
            modalTitle: "Warning",
            modalText: `You have already registered for the <br/><span class="primary-color" style="font-weight: bold">CF Exceed 2021 Event.</span>`,
            modalVisible: true
          });
        }
      });
  }

  render() {
    return (
      <div className="body background">
        <Popup onClose={() => this.setState({ modalVisible: false })} visible={this.state.modalVisible} title={this.state.modalTitle} text={this.state.modalText}/>
        <LoginModal onClose={() => this.setState({ modalLoginVisible: false })} visible={this.state.modalLoginVisible}/>
        <HomeHeader/>

        <div className="my-container secondary-font" style={{marginTop: "20px"}}>
          <div style={{marginBottom: '30px'}}>
            <div className="title-margin secondary-color" style={{fontSize: '44px', fontWeight: 'bold', fontFamily: 'Planer', lineHeight: '3rem', marginBottom: '1rem'}}>
              CF EXCEED 2021 Educational Portal
            </div>
          </div>
          <div className="row" style={{marginBottom: "10px"}}>
            <div className="col-sm-12 secondary-font primary-color" style={{fontWeight: "bold", fontSize: "26px"}}>
              REGISTRATION
            </div>
          </div>
          <div className="row" style={{marginBottom: "10px"}}>
            <div className="col-sm-12 primary-font secondary-color" style={{fontWeight: "bold", fontSize: "22px"}}>
              Personal Information
            </div>
          </div>
          <div className="row" style={{marginBottom: "50px"}}>
            <div className="col-sm-12 secondary-font primary-color">
              I agree and understand that by submitting this form, the information I include will be processed by Vertex Pharmaceuticals Incorporated, its
              global affiliates, and its service providers who provide services related to this event, to process your registration (including to facilitate and
              for record keeping obligations), to comply with Vertex’s legal obligations, for customer relationship management, and as further described
              in the Vertex Privacy Notice. Please do not include any health data in your registration.
            </div>
          </div>

          <div className="row secondary-font tertiary-color" style={{marginBottom: "50px"}}>
            <div className="col-md-10 col-xl-9">
              <div className="row">
                { this.renderFields() }
              </div>
            </div>
          </div>

          <div className="row" style={{marginBottom: '50px'}}>
            <div className="col-sm-12">
              <div onClick={() => this.register()} className={"background-primary border-all border-1 border-primary"} style={{color: 'white', marginTop: '20px', cursor: 'pointer', width: '189px', borderRadius: '10px', padding: '10px 50px', fontFamily: 'Planer', fontWeight: 'bold', fontSize: '20px'}}>
                REGISTER
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Registration
