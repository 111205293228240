import React, { useState, useEffect } from 'react';

import logo from './logo.svg';
import './App.css';
import ReactPlayer from 'react-player'
import VerifyEmailPage from './VerifyEmailPage.js'
import ContentPage from './ContentPage.js'
import Header from './Header.js'
import Footer from './Footer.js'
import Home from './Home.js'
import Registration from './Registration.js'
import PollResults from './PollResults.js'
import EducationalContentPage from './EducationalContentPage.js'
import CookiesPolicy from './CookiesPolicy.js'
import ScrollToTop from './ScrollToTop.js'

import axios from './myAxios';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useParams
} from "react-router-dom";

function App() {

  const [styling, setStyling] = useState([]);

  useEffect(()=>{
    axios.get(`/admin/api/getStyling.php`)
      .then(res => {
        setStyling(res.data);
        console.log(res.data);
      });
  }, []);

  return (
    <div>
      <style>
        {
          "\
            .background{\
              background: "+styling.background+"\
            }\
            .primary-font{\
              font-family:"+styling.primaryFont+";\
            }\
            .secondary-font{\
              font-family:"+styling.secondaryFont+";\
            }\
            .primary-color{\
              color:"+styling.primaryColor+"!important;\
            }\
            .secondary-color{\
              color:"+styling.secondaryColor+"!important;\
            }\
            .tertiary-color{\
              color:"+styling.tertiaryColor+"!important;\
            }\
            .background-primary{\
              background-color:"+styling.primaryColor+"!important;\
            }\
            .background-secondary{\
              background-color:"+styling.secondaryColor+"!important;\
            }\
            \
            .border-all{\
              border-style: solid!important\
            }\
            .border-top{\
              border-top-style: solid!important\
            }\
            .border-right{\
              border-right-style: solid!important\
            }\
            .border-bottom{\
              border-bottom-style: solid!important\
            }\
            .border-left{\
              border-left-style: solid!important\
            }\
            .border-1{\
              border-width: 1px!important;\
            }\
            .border-2{\
              border-width: 2px!important;\
            }\
            .border-3{\
              border-width: 3px!important;\
            }\
            .border-4{\
              border-width: 4px!important;\
            }\
            .border-5{\
              border-width: 5px!important;\
            }\
            \
            .border-primary{\
              border-color: "+styling.primaryColor+"!important;\
            }\
            .border-secondary{\
              border-color: "+styling.secondaryColor+"!important;\
            }\
            \
            .my-navbar .navbar-nav > li > a{\
              color:"+styling.primaryColor+";\
            }\
            #menu > li > a{\
              color: "+styling.primaryColor+";\
            }\
          "
        }
      </style>
      <div className="primary-font primary-color background">
        <Router>
          <ScrollToTop />
          <Switch>
            <Route path="/content/:id" component={ContentPage} />

            <Route path="/results">
              <Header/>
              <PollResults/>
            </Route>

            <Route path="/content">
              <Header/>
              <div className={"primary-color"} style={{position: "relative", width: "100%", backgroundColor: "white", padding: "30px 40px 20px", textAlign: "center"}}>
                <Link to={"home"}>
                  <div className={"primary-color"} style={{position: "absolute", left: "40px", top: "20px", fontSize: "35px"}}>
                    &lt;
                  </div>
                </Link>
                <span className="educationalContent" style={{fontWeight: "bold"}}>
                  EDUCATIONAL CONTENT
                </span>
              </div>
              <EducationalContentPage/>
            </Route>

            <Route path="/home/:login" component={Home} />
            <Route path="/home" component={Home} />

            <Route path="/cookies" component={CookiesPolicy} />

            <Route path="/register">
              <Registration/>
            </Route>

            <Route path="/verify/:token" component={VerifyEmailPage} />

            <Route render={() => <Redirect to="/home" />} />

          </Switch>
          <Footer/>
        </Router>
      </div>
    </div>
  );
}

export default App;
