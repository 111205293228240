import React, { Component } from 'react'
import './LoginModal.scss';
import { findDOMNode } from 'react-dom'
import UserAction from '../UserAction.js';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  withRouter
} from "react-router-dom";

import axios from '../myAxios';

import EmailInput from '../LoginInput/EmailInput.js';
import PasswordInput from '../LoginInput/PasswordInput.js';
import ForgotPasswordModal from './ForgotPasswordModal.js';

class LoginModal extends Component {
  constructor(props) {
    super(props);

    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
  }

  state = {
    email: "",
    password: "",
    errorVisible: false,
    successVisible: false,
    modalForgotVisible: false,
    modalForgotTitle: "",
    modalForgotText: "",
    hasError: false
  }

  componentDidMount() {

  }

  handleEmailChange(value) {
    this.setState({email: value});
  }

  handlePasswordChange(value) {
    this.setState({password: value});
  }

  Login(){
    this.setState({successVisible: false, errorVisible: false});
    let values = {};
    values["email"] = this.state.email;
    values["password"] = this.state.password;
    axios.post(`/admin/api/login.php`, values)
      .then(res => {
        console.log(res);
        if(res.data == "1"){
          this.setState({successVisible: true});
          UserAction.Save("Login", "Clicked");
          window.location.href = "/content";
        }
        else{
          this.setState({errorVisible: true});
        }
      });
  }

  handleOnErrorChange = (value) => {
    this.setState({hasError: value});
  }

  render() {
    return (
      <div>
        <div id="my-modal" className="my-modal" style={{pointerEvents: this.props.visible&&!this.state.modalForgotVisible?"all":"none", fontSize: "16px"}}>
          <div id="my-modal-background" style={{transition: 'all 0.3s', zIndex: 14, position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, backgroundColor: this.props.visible&&!this.state.modalForgotVisible?"#0000005e":"#00000000"}}>
          </div>
          <div id="my-modal-parent" style={{opacity: this.props.visible&&!this.state.modalForgotVisible?1:0, transition: 'all 0.3s', zIndex: 15, position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', borderRadius: '15px', border: '1px solid #7D2C83', backgroundColor: "white", maxWidth: "90%", width: "400px", textAlign: "center"}}>
            <div style={{borderRadius: '15px 15px 0px 0px', fontSize: '26px', padding: '15px', textAlign: 'center', backgroundColor: '#7D2C83', color: 'white', fontFamily: 'Planer', fontWeight: 'bold'}}>
              LOGIN
            </div>
            <div id="my-modal-body" className="my-modal-body secondary-font" style={{padding: "25px 25px 10px 25px"}}>
              <div style={{textAlign: "left"}}>
                <p style={{marginTop: '0px'}}>
                  <EmailInput onChange={this.handleEmailChange} onErrorChange={this.handleOnErrorChange} />
                </p>
                <p>
                  <PasswordInput onChange={this.handlePasswordChange}/>
                  <div onClick={() => this.setState({modalForgotVisible: true})} className={"secondary-color"} style={{fontSize: '14px', textDecoration: "underline", cursor: "pointer"}}>Forgot Password?</div>
                </p>
                <p style={{textAlign: 'center', color: 'red', display: this.state.errorVisible?"block":"none"}}>
                  Wrong Credentials
                </p>
                <p style={{textAlign: 'center', color: 'green', display: this.state.successVisible?"block":"none"}}>
                  Logged in successfully
                </p>
                <p>
                </p>
              </div>

              <div style={{fontSize: '20px', textAlign: 'center', fontFamily: 'Planer', fontWeight: 'bold', marginBottom: "10px"}}>
                <div className="primary-font" onClick={this.props.onClose} style={{marginRight: "15px", cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#7D2C83', borderRadius: '5px', width: '100px', padding: '7px 14px'}}>
                  CANCEL
                </div>
                <div className="primary-font" onClick={() => this.Login()} style={{cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#7D2C83', borderRadius: '5px', width: '100px', padding: '7px 14px'}}>
                  LOGIN
                </div>
              </div>
            </div>
          </div>
        </div>
        <ForgotPasswordModal onClose={() => this.setState({ modalForgotVisible: false })} visible={this.state.modalForgotVisible} title={this.state.modalForgotTitle} text={this.state.modalForgotText}/>
      </div>
    )
  }
}

export default LoginModal
