import React, { Component } from 'react'
import './ContentPage.scss';

import logo from "./images/exceed.svg";

import { findDOMNode } from 'react-dom'

import MyPlayer from './MyPlayer.js'
import ContentInfo from './ContentInfo.js'
import Header from './Header.js'

import axios from './myAxios';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class VerifyEmailPage extends Component {
  state = {
    status: -1,
    email: ""
  }

  componentDidMount() {
    let values = {};
    values["token"] = this.props.match.params.token;
    axios.post(`/admin/api/verifyEmail.php`, values)
      .then(res => {
        this.setState({email: res.data["email"], status: res.data["status"]});
      });
  }

  render() {
    return (
      <div className="body background">
        <div className="navbar my-navbar title-margin">
          <div className="container-fluid" style={{display: 'flex', msFlexWrap: 'wrap', flexWrap: 'wrap', msFlexAlign: 'center', alignItems: 'center', msFlexPack: 'justify', justifyContent: 'space-between'}}>
            <div className="navbar-header">
              <Link to={"../home"}>
                <div className="navbar-brand" style={{height: '180px'}}>
                  <img src={logo} style={{height: '100%', width: 'auto', paddingLeft: '1px'}} />
                </div>
              </Link>
            </div>
          </div>
        </div>

        <div className="my-container secondary-font" style={{marginTop: "20px"}}>
          <div className="row" style={{marginTop: "30px"}}>
            <div className="col-sm-12" style={{fontSize: '18px', display: (this.state.status==1||this.state.status==2)?"block":"none"}}>
              <p>
                Thank you for registering for the <b>CF EXCEED 2021 Educational Portal.</b>
              </p>
              <p>
                This email confirms that you have registered to watch the webinar presentations,
                held during the <b>Vertex Regional CF EXCEED Interactive Virtual Webinar Series
                2021,</b> on 26-27 October 2021.
              </p>
              <p>
                Please use the following credentials, to <Link to={"/home/login"}>login</Link> to the Educational Portal:<br/>
                User ID: {this.state.email}<br/>
                Password: cfexceed2021
              </p>
              <p>
                If you have any question or problem, please contact <a href='mailto:support@mdata.gr'>support</a>
              </p>
              <p>
                CF EXCEED Team
              </p>
            </div>
          </div>
        </div>
        <div className={"border-top border-5 border-primary"} style={{width: "100%", padding: "20px 30px", fontSize: "12px", backgroundColor: "white", position: "fixed", left: "0", bottom: "0"}}>
          <div className="footerText primary-color">
            <a href="https://global.vrtx.com/international-privacy" target="_blank" className="primary-color">Privacy Policy</a> | <Link to={"/cookies"} className="primary-color">Cookies Policy</Link><br/>
            <b>EXCEED is an educational programme funded by Vertex Pharmaceuticals (Europe) Ltd.</b><br/>
            BH-20-2100022 | October 2021
          </div>
          <div className="footerImage">
            <img src={axios.defaults.baseURL + "/documents/vrtx.png"} alt="" style={{height: "50px", width: "auto"}}/>
          </div>
        </div>
      </div>
    )
  }
}

export default VerifyEmailPage
