import React, { Component } from 'react'
import './Footer.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import UserAction from './UserAction.js'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import axios from './myAxios';

class Footer extends Component {
  state = {

  }

  componentDidMount() {

  }

  userActionMethod(){
    UserAction.Save("Privacy", "Visited");
  }

  render() {
    return (
      <div className={"border-top border-5 border-primary"} style={{width: "100%", padding: "20px 30px", fontSize: "12px", backgroundColor: "white"}}>
        <div className="footerText primary-color">
          <a onClick={this.userActionMethod} href="https://global.vrtx.com/international-privacy" target="_blank" className="primary-color">Privacy Policy</a> | <Link to={"/cookies"} className="primary-color">Cookies Policy</Link><br/>
          <b>EXCEED is an educational programme funded by Vertex Pharmaceuticals (Europe) Ltd.</b><br/>
          BH-20-2100008 | October 2021
        </div>
        <div className="footerImage">
          <img src={axios.defaults.baseURL + "/documents/vrtx.png"} alt="" style={{height: "50px", width: "auto"}}/>
        </div>
      </div>
    )
  }
}

export default Footer
