import React, { Component } from 'react'
import './ForgotPasswordModal.scss';
import { findDOMNode } from 'react-dom'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

import axios from '../myAxios';

import EmailInput from '../LoginInput/EmailInput.js';

class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);

    this.handleEmailChange = this.handleEmailChange.bind(this);
  }

  state = {
    email: "",
    successVisible: false,
    errorVisible: false,
    isOK: false
  }

  componentDidMount() {
    this.setState({isOK: false});
  }

  handleEmailChange(value) {
    this.setState({email: value});
  }

  Submit(){
    this.setState({successVisible: false, errorVisible: false});
    let values = {};
    values["email"] = this.state.email;
    axios.post(`/admin/api/forgotPassword.php`, values)
      .then(res => {
        console.log(res);
        if(res.data == "1"){
          this.setState({successVisible: true, isOK: true});
        }
        else{
          this.setState({errorVisible: true});
        }
      });
  }

  Close(){
    this.setState({successVisible: false, errorVisible: false, isOK: false});
    this.props.onClose();
  }

  handleOnErrorChange = (value) => {

  }

  render() {
    return (
      <div>
        <div id="my-modal" className="my-modal" style={{pointerEvents: this.props.visible?"all":"none", fontSize: "16px"}}>
          <div id="my-modal-background" style={{transition: 'all 0.3s', zIndex: 14, position: 'fixed', width: '100vw', height: '100vh', left: 0, top: 0, backgroundColor: this.props.visible?"#0000005e":"#00000000"}}>
          </div>
          <div id="my-modal-parent" style={{opacity: this.props.visible?1:0, transition: 'all 0.3s', zIndex: 15, position: 'fixed', left: '50%', top: '50%', transform: 'translate(-50%,-50%)', borderRadius: '15px', border: '1px solid #7D2C83', backgroundColor: "white", maxWidth: "90%", width: "400px", textAlign: "center"}}>
            <div style={{borderRadius: '15px 15px 0px 0px', fontSize: '26px', padding: '15px', textAlign: 'center', backgroundColor: '#7D2C83', color: 'white', fontFamily: 'Planer', fontWeight: 'bold'}}>
              Recover Password
            </div>
            <div id="my-modal-body" className="my-modal-body secondary-font" style={{padding: "25px 25px 10px 25px"}}>
              <div style={{textAlign: "left"}}>
                <p style={{marginTop: '0px'}}>
                  <EmailInput onChange={this.handleEmailChange} onErrorChange={this.handleOnErrorChange} />
                </p>
                <p style={{textAlign: 'center', color: 'red', display: this.state.errorVisible?"block":"none"}}>
                  The email you entered is not found. Try again.
                </p>
                <p style={{textAlign: 'center', color: 'green', display: this.state.successVisible?"block":"none"}}>
                  Password sent to your email
                </p>
                <p>
                </p>
              </div>

              <div style={{display: this.state.isOK?"none":"block", fontSize: '20px', textAlign: 'center', fontFamily: 'Planer', fontWeight: 'bold', marginBottom: "10px"}}>
                <div className="primary-font" onClick={() => this.Close()} style={{marginRight: "15px", cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#7D2C83', borderRadius: '5px', width: '100px', padding: '7px 14px'}}>
                  CANCEL
                </div>
                <div className="primary-font" onClick={() => this.Submit()} style={{cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#7D2C83', borderRadius: '5px', width: '100px', padding: '7px 14px'}}>
                  SUBMIT
                </div>
              </div>
              <div style={{display: this.state.isOK?"block":"none", fontSize: '20px', textAlign: 'center', fontFamily: 'Planer', fontWeight: 'bold', marginBottom: "10px"}}>
                <div className="primary-font" onClick={() => this.Close()} style={{marginRight: "15px", cursor: 'pointer', display: 'inline-block', color: 'white', backgroundColor: '#7D2C83', borderRadius: '5px', width: '100px', padding: '7px 14px'}}>
                  OK
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ForgotPasswordModal
