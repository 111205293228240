import React, { Component } from 'react'
import './ContentInfo.scss';
import { findDOMNode } from 'react-dom'

import { Container, Row, Col, Collapse, CardBody, Card } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import SpeakerBio from './SpeakerBio.js'
import EvaluationForm from './EvaluationForm.js'

import axios from './myAxios';
import UserAction from './UserAction';

class ContentInfo extends Component {
  state = {
    data: {},
    isOpen: false
  }

  toggleCollapse(){
    this.setState({ isOpen: !this.state.isOpen });
  }

  renderPresenters(){
    let result = [];
    (this.state.data["creator_full"] || []).forEach((item, i) => {
      result.push(<SpeakerBio creator={item} />);
    });
    return result;
  }

  formatDate(dateString){
    if(!dateString){
      return "";
    }
    let dateArr = dateString.split("-");
    let date = new Date(dateArr[0], dateArr[1]-1, dateArr[2]);
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
  }

  renderInteractiveFeatures(){
    let result = [];
    (this.state.data["interactive_features"] || []).forEach(feature => {
      if(feature.type_id == 1){ //Ask Question
        result.push(<a href={"mailto:"+feature.data} style={{textDecoration: "none"}}>
                      <div className={"background-secondary"} style={{marginTop: "10px", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "5px", textAlign: "center", fontSize: "16px"}}>
                        {feature.button_text}
                      </div>
                    </a>);
      }
      else if(feature.type_id == 2){ //Download Content
        result.push(<a href={"/admin/api/downloadContentMaterial.php?id=" + feature.data} style={{textDecoration: "none"}}><div className={"background-secondary"} style={{marginTop: "10px", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "5px", textAlign: "center", fontSize: "16px"}}>
                        {feature.button_text}
                      </div></a>);
      }
      else if(feature.type_id == 3){ //Evaluation Form
        // result.push(<a href={feature.data} target="_blank" style={{textDecoration: "none"}}>
        //               <div className={"background-secondary"} style={{marginTop: "10px", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "5px", textAlign: "center", fontSize: "16px"}}>
        //                 {feature.button_text}
        //               </div>
        //             </a>);
        result.push(<EvaluationForm playedSeconds={this.props.playedSeconds} activate_at={feature.activate_at} buttonText={feature.button_text} contentId={this.props.id} data={feature.data} style={{textDecoration: "none"}}/>);
      }
    })
    return result;
  }

  componentDidMount() {
    axios.get(`/admin/api/getContentDetails.php`, { params: { id: this.props.id } })
      .then(res => {
        console.log(res.data);
        this.setState({data: res.data});
      });
  }

  render() {
    return (
      <div style={{width: "100%", padding: "3px 30px", fontSize: "14px"}}>
        <Container fluid={true}>
          <Row>
            <Col>
              <div className={"primary-color"} style={{fontSize: "26px", fontWeight: "bold"}}>
                { this.state.data["content_title"] }
              </div>
              <div className={"secondary-font tertiary-color"}>
                { this.state.data["description"] }
              </div>
              <div style={{marginTop: "20px"}}>
                { this.renderPresenters() }
              </div>
            </Col>
            <Col md="auto">
              <div className={"dateAndForm secondary-font"} style={{borderRadius: "5px"}}>
                <div className={"tertiary-color"}>
                  { this.formatDate(this.state.data["creation_date"])}, { this.state.data["duration_size"] }
                </div>
              </div>

              <div className="interactive_features">
                { this.renderInteractiveFeatures() }
              </div>
 
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default ContentInfo
