import React, { Component, PureComponent } from 'react'
import './Question.scss';

class Question extends PureComponent {
  state = {

  }

//   shouldComponentUpdate(){

//   }

  renderAnswers(){
    if(this.props.data["votes_allowed"] == 1){
      return this.renderRadio();
    }
    else{
      return this.renderCheckboxes();
    }
  }

  handleRadioChange(e){
    let answers = [];
    if(this.props.data["votes_allowed"] == 1){ //We have radio
      answers.push(e.currentTarget.id);
    }
    else{ //We have checkboxes

    }
    this.props.onVoteHandler(this.props.votingId, this.props.questionId, answers);
  }

  renderRadio(){
    let result = [];
    this.props.data["answers"].forEach(answer => {
      result.push(<li>
                    <label>
                      <input type="radio" onChange={this.handleRadioChange.bind(this)} id={answer["id"]} name={this.props.data["question"]}></input>
                      {answer["answer"]}
                    </label><br/>
                  </li>)
    });
    return result;
  }

  renderCheckboxes(){
    let result = [];
    this.props.data["answers"].forEach(answer => {
      result.push(<li>
                    <label>
                      <input type="checkbox" id={answer["id"]} name={this.props.data["question"]}></input>
                      {answer["answer"]}
                    </label><br/>
                  </li>)
    });
    return result;
  }

  render() {
    return (
      <div style={{width: "100%", display: "inline-block"}}>
        <p style={{fontWeight: "bold", textAlign: "center"}}>
          { this.props.data["question"] }
        </p>
        <div>
          <ul className={"answerList tertiary-color secondary-font"} style={{fontSize: "90%", listStyle: "none", padding: "0px"}}>
            { this.renderAnswers() }
          </ul>
        </div>
      </div>
    )
  }
}

export default Question
