import React, { Component } from 'react'
import './EvaluationForm.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import QuestionsContainer from './QuestionsContainer.js'

import axios from './myAxios';

class EvaluationForm extends Component {
  state = {
    data: {},
    answer: {},
    showPopup: false,
    showContinue: false,
    showThankYou: false,
    activate: "00:00:00"
  }

  renderAnswers(){
    let questions = this.state.data["questions"];
    let result = [];
    for(const property in questions){
      questions[property]["answers"].forEach(answers => {
        result.push(<li>{answers["answer"]}</li>)
      });
      break;
    }
    return result;
  }

  submit(){
    console.log(JSON.stringify(this.state.answer));
    axios.post("/admin/api/saveEvaluationFormAnswer.php", JSON.stringify(this.state.answer))
      .then((response)=> {
        this.setState({showContinue: false, showThankYou: true, answer: {}});
        setTimeout(()=>{
          this.closePopup();
        },2000);
        console.log(response);
      })
      .catch(function (error) {
        alert(error);
        console.log(error);
      });
    console.log(this.state.answer);
  }

  closePopup(){
    this.setState({showPopup: false, showThankYou: false});
  }

  componentDidUpdate(){

  }

  componentDidMount() {
    this.setState({showContinue: false, showThankYou: false, data: JSON.parse(this.props.data)});
    //this.setState({activate: this.props.activate_at})
  }

  handleRadioChange(e){
    let answer = {};
    answer["content_id"] = this.props.contentId;
    answer["answer"] = e.currentTarget.id;
    this.setState({answer: answer, showContinue: true})
  }

  renderAnswers(){
    let result = [];
    (this.state.data["answers"] || []).forEach(answer => {
      result.push(<li>
                    <label>
                      <input type="radio" onChange={this.handleRadioChange.bind(this)} id={answer["answer"]} name={this.state.data["question"]}></input>
                      {answer["answer"]}
                    </label><br/>
                  </li>)
    });
    return result;
  }

  //, cursor: "pointer", marginTop: "10px", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "5px", textAlign: "center", fontSize: "16px"
  //title={"The button will be activated after " + this.state.activate} style={{pointerEvents: this.props.playedSeconds >= this.props.activate_at?"all":"none"}}
  render() {
    return (
      <div style={{display: this.props.playedSeconds >= this.props.activate_at?"block":"none"}}>
        <div className={"evalution-popup-bg"} style={{opacity: this.state.showPopup?"1":"0", pointerEvents: this.state.showPopup?"all":"none", transition: "all 0.3s" }}>
          <div className={"evalution-popup border-all border-2 border-primary primary-color"} style={{borderRadius: "20px"}}>
            <div onClick={()=> this.closePopup()} style={{cursor: "pointer", position: "absolute", top: "0px", right: "15px", fontWeight: "bold"}}>
              x
            </div>
            <div style={{display: "flex", flexFlow: "column", height: "100%"}}>
              <div className={"topText secondary-font"}>
                {this.state.data["top_text"] || ""}
              </div>
              <div style={{maxWidth: "100%", flex: "1 1 auto", overflowY: "auto"}} className={"questionsContainer secondary-font"}>
                <div style={{overflowX: "hidden", display: "flex", scrollSnapType: "x mandatory"}} className={"scroller"}>
                  <div style={{width: "100%", display: "inline-block"}}>
                    <p style={{fontWeight: "bold", textAlign: "center"}}>
                      { this.state.data["question"] || "" }
                    </p>
                    <div>
                      <ul className={"answerList tertiary-color secondary-font"} style={{fontSize: "90%", listStyle: "none", padding: "0px"}}>
                        { this.renderAnswers() }
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{flex: "0 1 30px", textAlign: "center", paddingTop: "15px", minHeight: "40px"}}>
                <div onClick={()=> this.submit()} className={"background-primary secondary-font"} style={{fontWeight: "bold", padding: "5px 20px", color: "white", borderRadius: "10px", cursor: "pointer", display: this.state.showContinue?"inline-block":"none", fontSize: "16px"}}>
                  Submit
                </div>
                <div className={"secondary-color"} style={{display: this.state.showThankYou?"block":"none", fontWeight: "bold", fontSize: "18px"}}>
                  Thank you for your feedback!
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"background-secondary"} onClick={()=> this.setState({showPopup: true})} style={{cursor: "pointer", marginTop: "10px", padding: "15px", color: "white", fontWeight: "bold", borderRadius: "5px", textAlign: "center", fontSize: "16px"}}>
          {this.props.buttonText}
        </div>
      </div>
    )
  }
}

export default EvaluationForm
