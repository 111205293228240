import React, { Component } from 'react'
import './ContentPage.scss';
import { findDOMNode } from 'react-dom'

import MyPlayer from './MyPlayer.js'
import ContentInfo from './ContentInfo.js'
import Header from './Header.js'
import axios from './myAxios';
import UserAction from './UserAction.js';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class ContentPage extends Component {
  state = {
    playedSeconds: 0
  }

  componentDidMount() {
    UserAction.Save("Content "+this.props.match.params.id, "Visited");
  }

  render() {
    return (
      <div style={{paddingBottom: "50px", backgroundColor: "white"}}>
        <Header/>
          <Link to={"/content"}>
            <div className="arrow" style={{position: "absolute", top: "32px", fontSize: "35px", color: "white"}}>
              <img src={axios.defaults.baseURL + "/documents/arrow.png"} style={{height: "20px", width: "auto"}}/>
            </div>
          </Link>
        
        <div style={{width: "100%", height: "calc(100vh - 170px)"}}>
          <MyPlayer setPlayedSeconds={(sec) => this.setState({playedSeconds: sec})} id={this.props.match.params.id}/>
        </div>
        <ContentInfo playedSeconds={this.state.playedSeconds} id={this.props.match.params.id}/>
      </div>
    )
  }
}

export default ContentPage
