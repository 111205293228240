import React, { Component } from 'react'
import './EmailInput.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class EmailInput extends Component {
  state = {
    value: "",
    hasError: true,
    hasFirstTyped: false
  }

  componentDidMount() {
    let error = true;
    if(this.props.options["required"] == 0){ //Setting initial error value
      error = false;
    }
    this.props.onErrorChange(this.props.name, error);
    this.setState({
      hasError: error,
      hasFirstTyped: false
    });
  }

  handleChange(event) {
    let value = event.target.value.trim().toLowerCase();
    this.setState({value: value});
    if(!this.state.hasFirstTyped){
      this.setState({hasFirstTyped: true});
    }
    this.props.onChange(this.props.name, value);

    let error = false;

    if(value == ""){
      if(this.props.options["required"]==1){
        error = true;
      }
    }
    else{
      if(value.length < this.props.options["min"]){
        error = true;
      }
      else if(value.length > this.props.options["max"]){
        error = true;
      }
      if(!this.validateEmail(value)){
        error = true;
      }
    }

    this.props.onErrorChange(this.props.name, error);
    this.setState({hasError: error});
  }

  validateEmail(email){
    let reg = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

    if (reg.test(email) == false)
    {
      return false;
    }
    return true;
  }

  render() {
    return (
      <div className={"col-sm-12 col-md-6 col-xl-"+this.props.options["size"]} style={{marginBottom: "20px"}}>
        <div style={{fontSize: "14px"}}>
          { this.props.name }{ this.props.options["required"]==1?"*":"" }
        </div>
        <input className={"textInput"} type="text" value={this.state.value} onChange={this.handleChange.bind(this)} style={{width: "100%", border: this.state.hasError&&this.state.hasFirstTyped?"1px solid red":""}}/>
        <div style={{color: "red", fontSize: "13px", display: this.state.hasError&&this.state.hasFirstTyped?"block":"none"}}>
          Please enter a valid email
        </div>
      </div>
    )
  }
}

export default EmailInput
