import React, { Component } from 'react'
import './QuestionsContainer.scss';

import Question from './Question.js'

import axios from './myAxios';

import {isIOS} from 'react-device-detect';

var hasVoted = {};
var currentQuestion = 0;
var questionsAnswered = 0;
var totalQuestions = 0;

class QuestionsContainer extends Component {
  state = {
    showContinue: false,
    currentAnswers: {}
  }

  async scrollToNextItem() {
    await this.sendCurrentAnswers();
    questionsAnswered++;
    console.log(totalQuestions, questionsAnswered);

    const questionsContainer = document.querySelector('.questionsContainer');
    const scroller = questionsContainer.querySelector('.scroller');
    const itemWidth = questionsContainer.querySelector('.item').clientWidth;
    //if(scroller.scrollLeft < (scroller.scrollWidth - itemWidth)){ //We are not at the end
    if(questionsAnswered != totalQuestions){ //We are not at the end
      if(!isIOS)
        scroller.scrollBy({left: itemWidth, top: 0, behavior: 'smooth'});
      else
        scroller.scrollBy({left: itemWidth, top: 0});
    }
    else{ //We are at the end
      questionsAnswered = 0;
      scroller.scrollTo({left: 0, top: 0}); //Reset the scrolling position
      this.props.doneHandler();
    }
    this.setState({ showContinue: false });
    this.setState({ currentAnswers: {} });
  }
  scrollToPrevItem() {
    const questionsContainer = document.querySelector('.questionsContainer');
    const scroller = questionsContainer.querySelector('.scroller');
    const itemWidth = questionsContainer.querySelector('.item').clientWidth;
    scroller.scrollBy({left: -itemWidth, top: 0, behavior: 'smooth'});
  }

  sendCurrentAnswers = async() => {
    axios.post("/admin/api/saveVotingAnswers.php", JSON.stringify(this.state.currentAnswers))
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
    console.log(this.state.currentAnswers);
  }

  componentDidMount(){

  }

  onVoteHandler = async(votingId, questionId, answerIds ) => {
    await this.setState({ currentAnswers: {"voting_id": votingId, "question_id": questionId, "answer_id": answerIds} });
    console.log(votingId, questionId, answerIds);
    hasVoted[votingId + " " + questionId] = true;
    if(hasVoted[votingId + " " + currentQuestion]){
      this.setState({ showContinue: true });
    }
  }

  renderQuestions(){
    let result = [];

    if(this.props.questions){
      let i = 0;
      totalQuestions = 0;
      for(const property in this.props.questions){
        totalQuestions++;
        if(i++ == 0){
          currentQuestion = property; //Setting the currentQuestion as the first question
        }
        result.push(<div className={"item"} style={{minWidth: "100%", scrollSnapAlign: "center"}}>
                      <Question data={this.props.questions[property]}
                        onVoteHandler={this.onVoteHandler}
                        votingId={this.props.votingId}
                        questionId={property}
                      />
                    </div>);
      }
    }
    return result;
  }

  render() {
    return (
      <div style={{display: "flex", flexFlow: "column", height: "100%"}}>
        <div className={"topText secondary-font"}>
          {this.props.top_text || ""}
        </div>
        <div style={{maxWidth: "100%", flex: "1 1 auto", overflowY: "auto"}} className={"questionsContainer secondary-font"}>
          <div style={{overflowX: "hidden", display: "flex", scrollSnapType: "x mandatory"}} className={"scroller"}>
            { this.renderQuestions() }
          </div>
        </div>
        <div style={{flex: "0 1 30px", textAlign: "center", paddingTop: "15px", minHeight: "40px"}}>
          <div onClick={()=> this.scrollToNextItem()} className={"background-primary secondary-font"} style={{fontWeight: "bold", padding: "5px 20px", color: "white", borderRadius: "10px", cursor: "pointer", display: this.state.showContinue?"inline-block":"none", fontSize: "16px"}}>
            CONTINUE
          </div>
        </div>
      </div>
    )
  }
}

export default QuestionsContainer
