import axios from './myAxios';

const UserAction = {
    Save: function(action, type_of_action){
        let values = {};
        values["action"] = action;
        //console.log(values["action"]);
        //console.log(action);
        values["type_of_action"] = type_of_action;
        axios.post(`/admin/api/userAction.php`, values)
            .then(res => {
                console.log(res.data);
            });
    }
}

export default UserAction;