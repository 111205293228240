import React, { Component } from 'react'
import './Header.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import QuestionsContainer from './QuestionsContainer.js'

import axios from './myAxios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import UserAction from './UserAction.js'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class Header extends Component {
  state = {
    data: {}
  }

  componentDidMount() {
    axios.get(`/admin/api/getContentDetails.php`)
      .then(res => {
        this.setState({data: res.data});
      });
  }

  userActionMethod(){
    UserAction.Save("Logout", "Clicked");
  }

  render() {
    return (
      <div>
        <div className={"header background-primary"}>
          <Link to={"/home"}>
            <img src={axios.defaults.baseURL + "/documents/logo.png"} alt="" style={{height: "100px", width: "auto"}}/>
            <div className={"headerTitle"} style={{color: "white", fontWeight: "bold", fontSize: "24px"}}>
              CF EXCEED 2021 Educational Portal
            </div>
          </Link>
          <a onClick={this.userActionMethod} href="/admin/api/logout.php" style={{textDecoration: "none"}}>
            <div className={"desktop"} style={{cursor: "pointer", fontSize: "12px", position: "absolute", right: "40px", top: "50%", transform: "translate(0, -50%)", padding: "7px 18px", color: "white", border: "1px solid white", borderRadius: "5px"}}>
              LOGOUT
            </div>
          </a>
          <div className={"mobile"} style={{cursor: "pointer", fontSize: "24px", position: "absolute", right: "40px", top: "50%", transform: "translate(0, -50%)", color: "white"}}>
            <a href="/admin/api/logout.php" style={{textDecoration: "none", color: "white"}}>
              <FontAwesomeIcon icon={faSignOutAlt}/>
            </a>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
