import React, { Component } from 'react'
import './CountryInput.scss';
import { findDOMNode } from 'react-dom'
import screenfull from 'screenfull'

import Select from 'react-select';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";

class CountryInput extends Component {
  state = {
    value: "",
    hasError: true
  }

  componentDidMount() {
    let error = true;
    if(this.props.options["required"] == 0){ //Setting initial error value
      error = false;
    }
    this.props.onErrorChange(this.props.name, error);
    this.setState({hasError: error});
  }

  handleChange(e) {
    let value = e.value;
    this.setState({value: value});
    this.props.onChange(this.props.name, value);

    let error = false;

    if(value == ""){
      if(this.props.options["required"]==1){
        error = true;
      }
    }
    else{
      if(value.length < this.props.options["min"]){
        error = true;
      }
      else if(value.length > this.props.options["max"]){
        error = true;
      }
    }

    this.props.onErrorChange(this.props.name, error);
    this.setState({hasError: error});
  }

  render() {
    const styles = {
      dropdownIndicator: base => ({
        ...base,
        backgroundColor: "#686a6f",
        color: "white",
        borderRadius: "0px 3px 3px 0px"
      }),
      indicatorSeparator: base => ({
        ...base,
        display: "none"
      }),
      control: base => ({
        ...base,
        border: this.props.showRed&&this.state.hasError?"1px solid red":"1px solid #67696e",
      }),
      menu: provided => ({ ...provided, zIndex: 9999 })
    };

    return (
      <div className={"col-sm-12 col-md-6 col-xl-"+this.props.options["size"]} style={{marginBottom: "20px"}}>
        <div style={{fontSize: "14px"}}>
          { this.props.name }{ this.props.options["required"]==1?"*":"" }
        </div>
        <Select
          menuPortalTarget={document.body} //Adding to body, otherwise it would be cut if the parent div is not tall enough
          //value={"Mr."}
          onChange={this.handleChange.bind(this)}
          //className="optionInput2"
          classNamePrefix="select"
          styles={styles}
          //defaultValue={}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={true}
          name="color"
          placeholder=""
          options={this.props.countries}
        />
      </div>
    )
  }
}

export default CountryInput
